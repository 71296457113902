/**
 * This serves as a location where all tracking events
 * (by now, Mixpanel) are generated.
 *
 * When an event should be fired, the base event is created
 * and the dynamic properties from the specific event are added.
 *
 * The mixpanel.track function targets our proxy server, not
 * mixpanel directly.
 */

import type {
    ApiProject,
    ApiProjectEntry,
    DiscoveryState,
    ProjectWizardState,
} from '@main/api/resources/projects';
import type { ApiRfxInvitation } from '@main/api/resources/rfx';
import type { FileExtension } from '@main/components/App/Projects/Modals/ExportProjectModal.vue';
import { useAuthStore } from '@main/store/stores/auth';
import { useCustomerStore } from '@main/store/stores/customer';
import { useProjectsStore } from '@main/store/stores/projects';
import { acceptHMRUpdate, defineStore } from 'pinia';
import type { Route } from 'vue-router';

type State = {
    baseProperties: BaseProperties | null;
    rfxContext: RfxContext | null;
};

export const useTrackingStore = defineStore( 'tracking', {
    persist: false,

    state(): State {
        return {
            // Base properties are sent with every event.
            baseProperties: null,

            // The context, if the current user is an RFX user.
            rfxContext: null,
        };
    },

    actions: {
        /**
         * We track any collaborator who are added to a project.
         */
        sendCollaboratorAdded( projectUuid: string, collaboratorId: string ) {
            return this.sendTrackingEvent( {
                event: 'Project collaborator added',
                properties: {
                    project_id: projectUuid,
                    collaborator_Id: collaboratorId,
                },
            } );
        },

        /**
         * We track when a user clicks on the help link in the app menu, the
         * header menu, or the 'More info' button in the database search.
         * We pass where the click was made.
         */
        sendHelpConsulted( context: string | undefined ) {
            return this.sendTrackingEvent( {
                event: 'Help consulted',
                properties: {
                    triggered_from: context ?? '',
                },
            } );
        },

        /**
         * We track any navigation to a new browser location.
         */
        sendPageViewed(
            { name: fromRoute, params: fromParams }: Route,
            { name: toRoute, params: toParams }: Route,
        ) {
            const fromId = fromParams?.uuid;
            const toId = toParams?.uuid;

            return this.sendTrackingEvent( {
                event: 'Page viewed',
                properties: {
                    from: fromRoute,
                    from_entity_id: fromId,
                    to: toRoute,
                    to_entity_id: toId,
                },
            } );
        },

        /**
         * We track any project which gets created. That is, the first step of
         * the project wizard gets completed.
         */
        sendProjectCreated( project: ApiProject ) {
            return this.sendTrackingEvent( {
                event: 'Project created',
                properties: {
                    ai_enabled: project.aiEnabled,
                    project_id: project.uuid,
                },
            } );
        },

        /**
         * We track any project update related to the project wizard. That is,
         * the wizard state of the project changes.
         */
        sendProjectUpdated( project: ApiProject, conversationStep?: string ) {
            return this.sendTrackingEvent( {
                event: 'Project updated',
                properties: {
                    ai_enabled: project.aiEnabled,
                    context_step: conversationStep,
                    project_id: project.uuid,
                    wizard_state: project.wizardState,
                },
            } );
        },

        /**
         * We track any project which gets deleted.
         */
        sendProjectDeleted( project: ApiProject, conversationStep?: string ) {
            return this.sendTrackingEvent( {
                event: 'Project deleted',
                properties: {
                    ai_enabled: project.aiEnabled,
                    context_step: conversationStep,
                    project_id: project.uuid,
                    wizard_state: project.wizardState,
                },
            } );
        },

        /**
         * We track any project which gets exported.
         */
        sendProjectExported(
            projectUuid: string,
            supplierCount: number,
            fields: string[],
            fileType: FileExtension,
        ) {
            return this.sendTrackingEvent( {
                event: 'Project exported',
                properties: {
                    fields: fields,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- false positive
                    file_type: fileType,
                    project_id: projectUuid,
                    supplier_count: supplierCount,
                },
            } );
        },

        /**
         * We track any project being switched from guided to basic,
         * because the AI assistant took too long.
         */
        sendProjectSwitched(
            switchedFromUuid: string,
            switchedToUuid: string,
            assistantStepWhenSwitched: string | undefined,
        ) {
            return this.sendTrackingEvent( {
                event: 'Project switched',
                properties: {
                    switched_from_project: switchedFromUuid,
                    switched_during_step: assistantStepWhenSwitched,
                    switched_to_project: switchedToUuid,
                },
            } );
        },

        /**
         * We track any search which gets triggered.
         */
        sendSearchTriggered( searchQueryId: string | undefined ) {
            return this.sendTrackingEvent( {
                event: 'Live search triggered',
                properties: { search_query_id: searchQueryId },
            } );
        },

        /**
         * We track any search which gets filtered.
         */
        sendSearchFiltered(
            searchQueryId: string | null,
            filterField: string | undefined,
            filter: string | string[],
        ) {
            return this.sendTrackingEvent( {
                event: 'Live search filtered',
                properties: {
                    filter_field: filterField,
                    filter_value: filter,
                    search_query_id: searchQueryId,
                },
            } );
        },

        /**
         * We track any search which gets reset.
         */
        sendSearchReset( searchQueryId: string | undefined ) {
            return this.sendTrackingEvent( {
                event: 'Live search reset',
                properties: {
                    search_query_id: searchQueryId,
                },
            } );
        },

        /**
         * We track whenever the user initially moves a project entry to a
         * project phase.
         *
         * We add the discovery state of the supplier by the time it was
         * discovered. This can be 'new', 'known', or 'active' and tells us
         * more about the ratio of suppliers which were indeed unknown to the
         * user vs. ones that were already known.
         */
        async sendSupplierDiscovered(
            projectUuid: string,
            entries: ApiProjectEntry[],
            toCurrentProject: boolean,
        ) {
            // Ensure we have the project and phase available.
            const projectsStore = useProjectsStore();
            await projectsStore.fetchProject( projectUuid );
            const project = projectsStore.getProject( projectUuid );

            if ( !project ) {
                throw new Error( `Project with uuid ${projectUuid} not found.` );
            }

            await Promise.all(
                entries.map( ( { discoveryState, phaseUuid, uuid } ) => {
                    // No initial phase is set.
                    if ( !phaseUuid ) {
                        return;
                    }

                    const phase = project.phases().get( phaseUuid );

                    return this.sendTrackingEvent( {
                        event: 'Supplier discovered',
                        properties: {
                            added_to_current_project: toCurrentProject,
                            discovery_state: discoveryState,
                            phase_name: phase?.name ?? '',
                            phase_order_number: phase?.order ?? 0,
                            project_id: projectUuid,
                            supplier_id: uuid,
                        },
                    } );
                } ),
            );
        },

        /**
         * We track whenever the user excludes a supplier from a project.
         */
        async sendSupplierExcluded( projectUuid: string, entries: ApiProjectEntry[] ) {
            // Ensure we have the project and phase available.
            const projectsStore = useProjectsStore();
            await projectsStore.fetchProject( projectUuid );
            const project = projectsStore.getProject( projectUuid );

            if ( !project ) {
                throw new Error( `Project with uuid ${projectUuid} not found.` );
            }

            await Promise.all(
                entries.map( ( entry ) =>
                    this.sendTrackingEvent( {
                        event: 'Supplier excluded',
                        properties: {
                            project_id: projectUuid,
                            search_query_id: entry.searchQuery,
                            supplier_id: entry.uuid,
                            ...( entry.reported ? { reported: true } : undefined ),
                        },
                    } ),
                ),
            );
        },

        /**
         * We track whenever the user moves a project entry from one phase to
         * another.
         */
        async sendSupplierMoved(
            projectUuid: string,
            entries: ApiProjectEntry[],
            previousPhaseUuid: string,
        ) {
            // Ensure we have the project and phase available.
            const projectsStore = useProjectsStore();
            await projectsStore.fetchProject( projectUuid );
            const project = projectsStore.getProject( projectUuid );

            if ( !project ) {
                throw new Error( `Project with uuid ${projectUuid} not found.` );
            }

            entries.forEach( ( { phaseUuid, uuid } ) => {
                if ( !phaseUuid ) {
                    // No initial phase is set.
                    return;
                }

                const phase = project.phases().get( phaseUuid );
                const previousPhase = project.phases().get( previousPhaseUuid );

                if ( !previousPhase ) {
                    throw new Error( `Previous phase with uuid ${previousPhaseUuid} not found.` );
                }

                return this.sendTrackingEvent( {
                    event: 'Supplier moved',
                    properties: {
                        phase_name: phase?.name ?? '',
                        phase_order_number: phase?.order ?? 0,
                        previous_phase_name: previousPhase.name,
                        previous_phase_order_number: previousPhase.order,
                        project_id: projectUuid,
                        supplier_id: uuid,
                    },
                } );
            } );
        },

        /**
         * We track when a user opens a supplier's URL.
         */
        sendSupplierUrlOpened(
            supplierUuid: string,
            url: string | undefined,
            clickedFrom: UrlClickLocation,
            projectUuid: string = 'undefined',
        ) {
            return this.sendTrackingEvent( {
                event: 'Supplier URL opened',
                properties: {
                    project_id: projectUuid,
                    supplier_id: supplierUuid,
                    triggered_from: clickedFrom,
                    url: url ?? '',
                },
            } );
        },

        /**
         * Send the actual event.
         */
        async sendTrackingEvent( trackingEvent: Event ) {
            const { event, properties } = trackingEvent;

            // As of now, we do not track anonymous users.
            const authStore = useAuthStore();

            if ( !authStore.user.uuid ) {
                console.log(
                    'Cannot send Mixpanel event - onboarded or RFX user data is not available.',
                );

                return;
            }

            // Base properties are sent with every event.
            if ( !this.baseProperties ) {
                this.baseProperties = buildBaseProperties( this.rfxContext );
            }

            const eventProperties = {
                ...this.baseProperties,
                ...properties,
            };

            // Check whether to send the event at all.
            if ( import.meta.env.VITE_ACTIVATE_THIRD_PARTY_PLUGINS === 'true' ) {
                const { mixpanel } = await import( '@main/plugins/mixpanel' );

                // Send event via proxy.
                try {
                    // Do not await the response.
                    // Just let's continue with whatever the user is doing.
                    mixpanel.track( event, eventProperties );
                } catch ( error ) {
                    // Fail silently. Do not prevent the user from doing their job.
                    // If this is a backend issue, it will be logged anyway.
                    console.error( 'Failed to send Mixpanel event', {
                        error,
                        event,
                        properties: eventProperties,
                    } );
                }
            } else {
                // Ensure we know what's going on in local development
                console.info( `Would now send Mixpanel event: ${event}`, { eventProperties } );
            }
        },

        setRfxUserContext( rfxContext: RfxContext ) {
            // At this point, we are not authorized yet.
            this.rfxContext = rfxContext;
        },
    },
} );

function buildBaseProperties( rfxContext: RfxContext | null ): BaseProperties {
    const customerStore = useCustomerStore();
    const authStore = useAuthStore();

    return {
        contact_person_id: authStore.isRfxUser ? authStore.user.uuid : undefined,
        customer_id: customerStore.customer.uuid,
        rfx_inviter_contact_person_id: rfxContext ? rfxContext.invitation.inviter?.uuid : undefined,
        rfx_project_id: rfxContext ? ( rfxContext.projectUuid ?? undefined ) : undefined,
        user_type: authStore.isRfxUser ? 'rfx' : 'onboarded',
        version: 3.4,
    };
}

if ( import.meta.hot ) {
    import.meta.hot.accept( acceptHMRUpdate( useTrackingStore, import.meta.hot ) );
}

type BaseProperties = {
    /**
     * Our search will drastically change in the future.
     * Hence, we want to version our Mixpanel events.
     */
    version: number;

    user_type: 'onboarded' | 'rfx';

    /**
     * All user types:
     * The contact person ID of the user.
     * If this is an RFX user, then this ID is identical to the user ID.
     *
     * @todo `contact_person_id` is not sent yet for onboarded users.
     */
    contact_person_id: string | undefined;

    /**
     * Onboarded users only:
     * The customer ID of the authorized, onboarded user.
     */
    customer_id: string | undefined;

    /**
     * RFX users only:
     * The ID contact person that belongs to the buyer, who invited the RFX user.
     */
    rfx_inviter_contact_person_id: string | undefined;

    /**
     * The ID of the buyer's project related to the RFX invitation
     */
    rfx_project_id: string | undefined;

    // TODO: We wanted to send the customer ID of the buyer.
    // It requires us to extend the API in order to provide
    // this information to the RFX user.
    // rfx_customer_id: string | undefined;
};

type TrackingEvent<T extends string, U extends Record<string, unknown>> = {
    event: T;
    properties: U;
};

type PageViewedEvent = TrackingEvent<
    'Page viewed',
    {
        from: string | null | undefined;
        to: string | null | undefined;
        from_entity_id: string | null | undefined;
        to_entity_id: string | null | undefined;
    }
>;

type SupplierEventBaseProperties = {
    project_id: string;
    supplier_id: string;
};

type SupplierDiscoveredEvent = TrackingEvent<
    'Supplier discovered',
    SupplierEventBaseProperties & {
        added_to_current_project: boolean;
        discovery_state: DiscoveryState;
        phase_name: string;
        phase_order_number: number;
    }
>;

type SupplierMovedEvent = TrackingEvent<
    'Supplier moved',
    SupplierEventBaseProperties & {
        phase_name: string;
        phase_order_number: number;
        previous_phase_name: string;
        previous_phase_order_number: number;
    }
>;

type SupplierExcludedEvent = TrackingEvent<
    'Supplier excluded',
    SupplierEventBaseProperties & {
        reported?: boolean;
        search_query_id?: string;
    }
>;

type SupplierUrlOpenedEvent = TrackingEvent<
    'Supplier URL opened',
    {
        supplier_id: string;
        triggered_from: UrlClickLocation;
        project_id?: string;
        url?: string;
    }
>;

export type UrlClickLocation =
    | 'exclusion'
    | 'full-profile'
    | 'manager'
    | 'phase-entry'
    | 'preview'
    | 'reference'
    | 'result';

type HelpConsultedEvent = TrackingEvent<
    'Help consulted',
    {
        triggered_from: string;
    }
>;

type SearchTriggeredEvent = TrackingEvent<
    'Live search triggered',
    {
        search_query_id: string | undefined;
    }
>;

type SearchFilteredEvent = TrackingEvent<
    'Live search filtered',
    {
        filter_field: string | undefined;
        filter_value: string | string[];
        search_query_id: string | null;
    }
>;

type SearchResetEvent = TrackingEvent<
    'Live search reset',
    {
        search_query_id: string | undefined;
    }
>;

type ProjectCreatedEvent = TrackingEvent<
    'Project created',
    {
        ai_enabled: boolean;
        project_id: string | null;
    }
>;

type ProjectDeletedEvent = TrackingEvent<
    'Project deleted',
    {
        ai_enabled: boolean;
        context_step: string | undefined;
        project_id: string;
        wizard_state: ProjectWizardState;
    }
>;

type ProjectUpdatedEvent = TrackingEvent<
    'Project updated',
    {
        ai_enabled: boolean;
        context_step: string | undefined;
        project_id: string;
        wizard_state: ProjectWizardState;
    }
>;

type ProjectExportedEvent = TrackingEvent<
    'Project exported',
    {
        fields: string[];
        file_type: FileExtension;
        project_id: string;
        supplier_count: number;
    }
>;

type ProjectSwitchedEvent = TrackingEvent<
    'Project switched',
    {
        switched_from_project: string;
        switched_during_step: string | undefined;
        switched_to_project: string;
    }
>;

type CollaboratorAddedEvent = TrackingEvent<
    'Project collaborator added',
    {
        project_id: string;
        collaborator_Id: string;
    }
>;

type Event =
    | PageViewedEvent
    | SupplierDiscoveredEvent
    | SupplierMovedEvent
    | SupplierExcludedEvent
    | SupplierUrlOpenedEvent
    | HelpConsultedEvent
    | SearchTriggeredEvent
    | SearchFilteredEvent
    | SearchResetEvent
    | ProjectCreatedEvent
    | ProjectUpdatedEvent
    | ProjectDeletedEvent
    | ProjectExportedEvent
    | ProjectSwitchedEvent
    | CollaboratorAddedEvent;

type RfxContext = {
    // The invitation on behalf of which the RFX user is acting.
    invitation: ApiRfxInvitation;

    // The project that is bound to the RFX invitation.
    projectUuid: string | null;
};
